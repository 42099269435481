import React from 'react'
import styled from 'styled-components'
import RemarkRenderer from 'components/RemarkRenderer'
import { mediaQuery } from 'utils/style'

const Container = styled.div`
  display: flex;
  ${mediaQuery()}
`

export default ({ data }) => (
  <Container id={data.hash}>
    <RemarkRenderer
      ast={data.content?.childMarkdownRemark?.htmlAst}
      clamp={data.clamp && 'auto'}
      style={{ width: '100%' }}
      {...(data.clamp
        ? { media: device => (device === 'mobile' ? 'max-height: 15em;' : '') }
        : {})}
    />
  </Container>
)
